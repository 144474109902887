import React from 'react';
import Layout from '../components/Layout';
import { withIntl } from '../i18n';
import Landing from '../templates/Landing';

const host = process.env.GATSBY_HOST;

const api = {
  header: {
    title: 'apiHeaderTitle',
    subTitle: 'apiHeaderSubTitle',
    image: 'api.png',
    button1: {
      text: 'registration',
      subText: 'registrationSubText',
      link: `${host}/auth2/single_account?redirect_uri=${host}/&enter=signup`,
      event: 'API_get_started',
      id: 'sign_up_api'
    },
    button2: {
      text: 'orderDemo',
      link: '/form/live-demo',
      event: 'API_request_demo',
      id: 'request_demo_api'
    }
  },
  content: [
    {
      title: 'apiContentSingleTitle',
      subTitle: 'apiContentSingleSubTitle',
      image: 'weel.svg',
      width: 400,
    },
    {
      title: 'apiContentAutoTitle',
      subTitle: 'apiContentAutoSubTitle',
      image: 'conveer.svg',
    },
    {
      title: 'apiContentWebhooksTitle',
      subTitle: 'apiContentWebhooksSubTitle',
      image: 'Net.svg',
    },
    {
      title: 'apiContentPerformanceTitle',
      subTitle: 'apiContentPerformanceSubTitle',
      image: 'Generator.svg',
    },
    {
      title: 'apiContentSecurityTitle',
      subTitle: 'apiContentSecuritySubTitle',
      image: 'Shield.svg',
    }
  ],
  demo: {
    event: 'digital_core_start'
  }
};

const ManageApi = (props) => (
  <Layout {...props}>
    <Landing
      data={api}
      page={'manage-api'}
    />
  </Layout>
);

export default withIntl(ManageApi);

